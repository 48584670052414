


/* { 
  name: '',
  website: '',
  logo: '',
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
}, */


 const explorersAds =[
    { 
        name: '3xpl',
        website: '3xpl.com/bitcoin-cash',
        logo: '3xpl.png',
        description: 'Whether you need to download blockchain data, make online analysis, or to get live JSON data for your app, we have got you covered.',
        socialHandles: ['discord.com/invite/twCa6g2Bge','twitter.com/3xplcom','github.com/3xplcom'],
        videos: [],
        longerDescription: '',
        tags:[],
      }, 
      { 
name: 'Token Explorer',
website: 'tokenexplorer.cash',
logo: 'nologo.png',
description: 'A BCMR Token explorer for Cashtokens by Mathieu Geukens.',
socialHandles: [],
videos: [],
longerDescription: '',
tags:['CashTokens'],
},
{ 
name: 'Explorer.salemkode',
website: 'explorer.salemkode.com',
logo: 'bitcoinunlim.png',
description: 'BCH block explorer with CashTokens support.',
socialHandles: ['github.com/salemkode/explorer'],
videos: [],
longerDescription: '',
tags:['CashTokens'],
},
{ 
name: 'Chipnet.imaginary',
website: 'chipnet.imaginary.cash',
logo: 'bitcoinunlim.png',
description: 'BCH ChipNet block explorer',
socialHandles: ['github.com/sickpig/bch-rpc-explorer'],
videos: [],
longerDescription: '',
tags:[],
},
{ 
name: 'Explorer.melroy',
website: 'explorer.melroy.org',
logo: 'bchexplorer.png',
description: 'Our mempool and blockchain explorer for the Bitcoin Cash community, focusing on the transaction fee market and multi-layer ecosystem.',
socialHandles: ['gitlab.melroy.org/bitcoincash/explorer','twitter.com/bchexplorer'],
videos: [],
longerDescription: 'completely self-hosted without any trusted third-parties.',
tags:[],
},
{ 
name: 'Explorer.bitcoinunlimited',
website: 'explorer.bitcoinunlimited.info',
logo: 'bitcoinunlim.png',
description: ' Bitcoin Cash explorer by the Bitcoin Unlimited node software team.',
socialHandles: ['github.com/sickpig/bch-rpc-explorer'],
videos: [],
longerDescription: '',
tags:[],
},
{ 
name: 'Blockchair.com/bitcoin-cash',
website: 'blockchair.com/bitcoin-cash',
logo: 'blockchair.png',
description: 'BCH ChipNet block explorer.',
socialHandles: ['twitter.com/Blockchair','t.me/BlockchairNews','github.com/Blockchair'],
videos: [],
longerDescription: '',
tags:[],
},
{ 
  name: 'Fulcrum Server',
  website: 'fulcrumserver.org',
  logo: 'fulcrum.png',
  description: 'A fast & nimble SPV server for Bitcoin Cash',
  socialHandles: ['github.com/cculianu/Fulcrum'],
  videos: [],
  longerDescription: '',
},
{ 
  name: 'Explorer.ElectronCash',
  website: 'explorer.electroncash.de',
  logo: 'bitcoinunlim.png',
  description: 'Simple, database-free Bitcoin Cash blockchain explorer, via RPC.',
  socialHandles: ['github.com/sickpig/bch-rpc-explorer'],
  videos: [],
  longerDescription: '',
},

{ 
name: 'Coingecko',
website: 'coingecko.com',
logo: 'coingecko.png',
description: 'Overall crypto market ranking and info site.',
socialHandles: ['twitter.com/coingecko','t.me/coingecko','discord.com/invite/EhrkaCH','www.youtube.com/@CoinGecko','reddit.com/r/coingecko/','instagram.com/coingecko/'],
videos: [],
longerDescription: '',
tags:[],
},
{ 
name: 'Coinmarketcap',
website: 'Coinmarketcap.com',
logo: 'coinmarketcap.png',
description: 'Overall crypto market ranking and info site.',
socialHandles: ['twitter.com/CoinMarketCap','t.me/CoinMarketCapAnnouncements','instagram.com/coinmarketcap/','reddit.com/r/CoinMarketCap/','facebook.com/CoinMarketCap'],
videos: [],
longerDescription: '',
tags:[],
},
{
  name: 'kallisti block explorer',
  website: 'explorer.bch.ninja/',
  logo: 'nologo.png',
  description: 'Bitcoin Cash block explorer.',
  socialHandles: [],
  videos:[],
  longerDescription: '',
  tags:[], 
},
{ 
  name: 'BTC.com',
  website: 'btc.com/en/bch',
  logo: 'btccom.png',
  description: 'The top blockchain explorer built by BTC.com in 2015, can offer users all information on the network they need efficiently.',
  socialHandles: ['btccom_pool','x.com/btccom_official','discord.com/invite/R8HzUvg2eR','www.facebook.com/btccom'],
  videos: [],
  longerDescription: 'To accelerate the development of the whole community, BTC.com pool opened source code of the pool and Smart Agent,etc.,anyone can access Github to view the back-end code ,meanwhile, the simulated stress test program for the tools is also published to Github.',
  tags:[],
},

]
export default explorersAds