export interface Ad {
  name?: string;
  website?: string;
  logo?: string;
  score: number;
  columns: (string | number)[]; 
  description?: string;
  socialHandles: string[];
  videos?: string[]; 
  longerDescription?: string;
}
/* { 
  name: '',
  website: '',
  logo: '',
  score: ,
  columns: ['', 'X', '',  '', '',''],
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
}, */


const DataExchanges = [
  { 
    name: 'Thorchain',
    website: 'Thorchain.org',
    logo: 'thorchain.png',
    score: 95,
    columns: ['DEX', 'X', '\u2714', '1',],
    description: 'Swap, Borrow, and Earn with your native assets. Powered by RUNE',
    socialHandles: ['twitter.com/thorchain','t.me/thorchain_org','medium.com/thorchain','gitlab.com/thorchain'],
    videos: [],
    longerDescription: 'THORChain is a network that facilitates native asset settlement between Bitcoin, Ethereum, BNB Chain, Avalanche, Cosmos Hub, Dogecoin, Bitcoin Cash & Litecoin',
    tags:[],
  }, 
  { 
    name: 'Atomicdex',
    website: 'Atomicdex.io',
    logo: 'atomicdex.png',
    score: 95,
    columns: ['DEX', 'X', '-', '-',],
    description: 'Rebranded to Komodo Wallet - is a multi-coin wallet, bridge, and DEX rolled into one app. 500+ cryptos across dozens of protocols.',
    socialHandles: ['twitter.com/KomodoPlatform','discord.com/invite/3rzDPAr','www.reddit.com/r/komodoplatform/','t.me/KomodoPlatform_Official','linktr.ee/komodoblockchain'],
    videos: [],
    longerDescription: 'Atomic swaps are designed such that both traders either receive the funds they desire, or nothing happens at all and both traders retain the funds they started with.',
    tags:[],
  },
  { 
    name: 'Shapeshift',
    website: 'Shapeshift.com',
    logo: 'shapeshift.png',
    score: 95,
    columns: ['DEX', 'X', '\u2714', '-',],
    description: 'A free open source platform to trade, track, buy, and earn. Community-owned. Private. Non-custodial. Multi-chain.',
    socialHandles: ['twitter.com/ShapeShift','discord.com/invite/shapeshift','t.me/shapeshiftofficial','www.youtube.com/channel/UCrZ2Ml63kLwZJ6amqoGaZeQ','www.instagram.com/shapeshift_io/','www.facebook.com/ShapeShiftPlatform/','medium.com/@ShapeShift.com'],
    videos: [],
    longerDescription: 'ShapeShift offers fully decentralized trading. This means you trade with other users and theres no centralized intermediary controlling your keys, unlike with central exchanges. Additionally, we dont add any commissions.',
    tags:[],
  },
  { 
    name: 'Hybrix',
    website: 'Hybrix.io',
    logo: 'hybrix.png',
    score: 95,
    columns: ['DEX', 'X', '-', '-',],
    description: 'Technically borderless cross-chain platform hybrix unites all digital ledgers.',
    socialHandles: ['gitlab.com/hybrix-public','twitter.com/hybrix_io','t.me/hybrix_io'],
    videos: [],
    longerDescription: 'hybrix introduces apps and dapps for your needs! For example: interact with the blockchain in the hybrix wallet or in hybrix explorer. We also have an recipe editor to quickly add tokens to the hybrix ecosystem, including wallet, explorer and other hybrix products, without the need for technical knowledge!',
    tags:[],
  },
  {
      name: 'Kraken',
      website: 'Kraken.com',
      logo: 'kraken.png',
      score: 95,
      columns: ['CEX', 'X', '\u2714', '15',],
      description: 'Kraken makes it easy to get started. Sign up today to buy and sell 200+ cryptocurrencies.',
      socialHandles: ['t.me/kraken_exchange_official','www.facebook.com/KrakenFX/','twitter.com/krakenfx','youtube.com/c/KrakenBitcoinExchange','reddit.com/r/Kraken/','linkedin.com/company/krakenfx/','tiktok.com/@krakenfx'],
      videos: [],
      longerDescription: 'Jesse Powell founded Kraken to give people the means to quickly and securely invest in the space. Whether you’re an advanced trader or a crypto-beginner, Kraken gives you the power to chart your own financial course. Our exchange has an ever-growing number of cryptocurrency pairs for you to invest in and a slew of tools and features for you to leverage as you grow your portfolio. From over-the-counter trading to personalized white-glove account management, Kraken is the premier cryptocurrency investing solution for institutions of all sizes. We offer exceptional liquidity and competitive pricing for all our markets so you can achieve your investment goals quickly and confidently. ',
      tags:[],
    },
    { 
      name: 'Sideshift',
      website: 'Sideshift.ai',
      logo: 'sideshift.png',
      score: 95,
      columns: ['CEX', 'X', '\u2714', '-',],
      description: 'The No Sign-Up Crypto Exchange // Shift between BTC, ETH, BCH, XMR, XAI and 100+ other cryptocurrencies, no account required. ',
      socialHandles: ['twitter.com/sideshiftai','t.me/sideshift','www.instagram.com/sideshift_ai/'],
      videos: [],
      longerDescription: 'SideShift.ai is AUTOMATIC. Once your deposit receives 1 confirmation on the BLOCKCHAIN, SideShift.ai will process your shift and send funds to your chosen address. The DURATION of this process is dependent on CONFIRMATION time. SideShift.ai does not hold funds. XAI is the native token of Sideshift. 210 million XAI tokens minted on ERC-20 ETH Network.',
      tags:[],
    },
    { 
      name: 'OKX',
      website: 'OKX.com',
      logo: 'okex.png',
      score: 95,
      columns: ['CEX', 'X','-', '-',],
      description: 'OKEx offers a fully customizable Bitcoin and cryptocurrency exchange for traders at every level',
      socialHandles: ['twitter.com/okx','www.tiktok.com/@okx?lang=en','www.instagram.com/okx_official/','discord.com/invite/e6EyvM5QwM','t.me/OKXOfficial_English','www.facebook.com/okxofficial/','www.linkedin.com/company/okxofficial/','www.youtube.com/channel/UCZEp9q993DknUPrhIL51lcw','www.reddit.com/r/OKX/'],
      videos: [],
      longerDescription: '',
      tags:[],
    },
    { 
      name: 'Blockchain',
      website: 'Blockchain.com',
      logo: 'blockchaincom.png',
      score: 95,
      columns: ['CEX', 'X', '-', '-',],
      description: 'Over 90 trading pairs including BTC, ETH, DeFi, Stablecoins, and more. The more you trade the lower your fees, giving you an edge.',
      socialHandles: ['twitter.com/blockchain','www.instagram.com/blockchainofficial/','medium.com/blockchain'],
      videos: [],
      longerDescription: 'Blockchain.com got its start as an early pioneer of key infrastructure for the bitcoin community. First, with a Blockchain Explorer that enabled anyone to not only examine transactions and study the blockchain, but an API that enabled companies to build on Bitcoin. We also have provided the most popular and widely used crypto wallet that enables anyone anywhere to control their own money.',
      tags:[],
    },
    { 
      name: 'Coinex',
      website: 'Coinex.com',
      logo: 'coinex.png',
      score: 95,
      columns: ['CEX', 'X', '-', '-',],
      description: 'Log in to trade 700+ cryptos. Trading with high-speed matching engine, very fast deposit and withdrawal experience',
      socialHandles: ['t.me/CoinExOfficialENG','www.reddit.com/r/Coinex/','www.facebook.com/TheCoinEx','coinex.medium.com/','twitter.com/coinexcom'],
      videos: [],
      longerDescription: 'CoinEx is a global crypto exchange committed to making crypto trading easier. Available in 15 languages, the exchange offers a wide range of products and services, including spot trading, futures trading, margin trading, AMM, crypto finance, and strategic trading. CoinEx provides easy-to-use, secure, and reliable crypto trading services for more than 4 million users in over 200 countries and regions.',
      tags:[],
    },
    { 
      name: 'Coinbase',
      website: 'Coinbase.com',
      logo: 'coinbase.png',
      score: 95,
      columns: ['CEX', 'X', '-', '-',],
      description: 'Trade 200+ assets securely. We offer a trusted and easy-to-use platform for accessing the broader cryptoeconomy. ',
      socialHandles: ['twitter.com/coinbase','www.facebook.com/Coinbase'],
      videos: [],
      longerDescription: 'Customers around the world discover and begin their journeys with crypto through Coinbase. 245,000 ecosystem partners in over 100 countries trust Coinbase to easily and securely invest, spend, save, earn, and use crypto. We are building the cryptoeconomy – a more fair, accessible, efficient, and transparent financial system enabled by crypto. We started in 2012 with the radical idea that anyone, anywhere, should be able to easily and securely send and receive Bitcoin.',
      tags:[],
    },
    { 
      name: 'Bitcoinvn',
      website: 'Bitcoinvn.io',
      logo: 'bitcoinvn.png',
      score: 95,
      columns: ['CEX', 'X', '-', '-',],
      description: 'Exchange in Vietnam - Buy, sell or swap between 85 assets including VND, BTC, USDT, USDC, ETH, BCH, XMR ',
      socialHandles: ['twitter.com/Bitcoin_Vietnam','vn.linkedin.com/company/bitcoinvn','t.me/bitcoinvn_community','www.tiktok.com/@bitcoinvn.io','www.facebook.com/www.bitcoinvn.io'],
      videos: [],
      longerDescription: 'We are the bridge between the fiat world and cryptocurrency in Vietnam. BitcoinVN is Vietnams premier Bitcoin and cryptocurrency exchange, dedicated to providing a safe, secure, and user-friendly platform for buying and selling cryptocurrencies.Our mission is to be the bridge between the fiat world and the global peer to peer economy in Vietnam, making it easy for locals and foreigners in Vietnam to access the benefits of this technology handed to us by Satoshi Nakamoto ',
      tags:[],
    },   
   { 
      name: 'Bitcoin.com',
      website: 'Bitcoin.com',
      logo: 'bitcoincom.png',
      score: 95,
      columns: ['CEX', 'X', '\u2714', '-',],
      description: 'Buy, sell, trade, and invest in Bitcoin & crypto - all in one safe and simple app.',
      socialHandles: ['t.me/www_Bitcoin_com','twitter.com/bitcoincom','www.youtube.com/channel/UCetxkZolEBHX47BqtZktbkg','www.instagram.com/bitcoin.com_official/','www.facebook.com/buy.bitcoin.news'],
      videos: ['https://www.youtube.com/embed/z5SfcRvmSxI'],
      longerDescription: 'Since 2015, Bitcoin.com has been a global leader in introducing newcomers to crypto. We make it easy for anyone to buy, spend, trade, invest, earn, and stay up-to-date on cryptocurrency and the future of finance.',
      tags:[],
    },
    { 
      name: 'Zapit Instant Exchange',
      website: 'zapit.io/instant-exchange',
      logo: 'zapit.png',
      score: 99,
      columns: ['CEX', 'X', '\u2714', '0',],
      description: 'Exchange your cryptocurrencies for Fiat & back through our instant exchange feature.',
      socialHandles: ['t.me/zapit_io','linktr.ee/zapit','twitter.com/zapit_io','discord.com/invite/qv24FrZRBE','github.com/zapit-io','youtube.com/channel/UC73ftzCuewJJv5p5wPSWyuw','instagram.com/zapit.io/','reddit.com/user/zapit_io','facebook.com/zapit.io/','medium.com/@zapit_io'],
      videos: [],
      longerDescription: `KYC required, Wallet appt Required. 
      \n Exchange your cryptocurrencies for Fiat & back through our instant exchange feature. Minimum hassle, maximum benefit, Instant!`,
      tags:[],
    },
    { 
      name: 'Zapit P2P Exchange',
      website: 'zapit.io/p2p-exchange',
      logo: 'zapit.png',
      score: 99,
      columns: ['P2P', 'X', '\u2714', '0',],
      description: 'Buy and sell without a central authority. Trade your crypto with our open source non custodial P2P exchange.',
      socialHandles: ['t.me/zapit_io','linktr.ee/zapit','twitter.com/zapit_io','discord.com/invite/qv24FrZRBE','github.com/zapit-io','youtube.com/channel/UC73ftzCuewJJv5p5wPSWyuw','instagram.com/zapit.io/','reddit.com/user/zapit_io','facebook.com/zapit.io/','medium.com/@zapit_io'],
      videos: ['https://www.youtube.com/embed/-M4KYfoajWA?list=PLgYNEqE8SMOFXbsn5D4hPwNB8HWdI-4Bv'],
      longerDescription: 'The only Non-Custodial Peer-to-PeerExchange in the World Buy and sell without a central authority. Trade your crypto with our open source non custodial P2P exchange. Confidential and safe.',
      tags:[],
    },
    { 
      name: 'Zapit CoinSwap',
      website: 'zapit.io/coinswap',
      logo: 'zapit.png',
      score: 99,
      columns: ['P2P', 'X', '\u2714', '0',],
      description: 'Swap between 15+ cryptocurrencies at the best exchange rates',
      socialHandles: ['t.me/zapit_io','linktr.ee/zapit','twitter.com/zapit_io','discord.com/invite/qv24FrZRBE','github.com/zapit-io','youtube.com/channel/UC73ftzCuewJJv5p5wPSWyuw','instagram.com/zapit.io/','reddit.com/user/zapit_io','facebook.com/zapit.io/','medium.com/@zapit_io'],
      videos: [],
      longerDescription: 'Swap between 15+ cryptocurrencies at the best exchange rates. Non-custodial, No Sign-up, No KYC, Anonymous and Instant.',
      tags:[],
    },
    { 
      name: 'StealthEX',
      website: 'stealthex.io',
      logo: 'stealthex.png',
      score: 99,
      columns: ['CEX', 'X', '\u2714', '-',],
      description: 'StealthEX is an instant cryptocurrency exchange for limitless swaps. Our service is free from registration and does not store user\'s funds on the platform.',
      socialHandles: ['twitter.com/StealthEX_io','youtube.com/channel/UCeES_XBesX76ge7xf1meuSw','stealthex-io.medium.com/','t.me/StealthEX','t.me/StealthEX_io'],
      videos: [],
      longerDescription: 'We have created a simple and minimalistic interface for everyone to make cryptocurrency exchanges. All the swaps can be done in just four steps. Besides, our team is here 24/7 to answer all your questions – just drop a message to support@stealthex.io`',
      tags:[],
    },

    // Add more ads in the Application category as needed
  ]

export default DataExchanges;

