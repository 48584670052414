
/* { 
  name: '',
  website: '',
  logo: '',
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
}, */
 const  storesAds = [
  { 
    name: 'Bitgree',
    website: 'bitgree.com',
    logo: 'bitgree.png',
    description: 'Buy almost anything using Bitcoin Cash. Or earn Bitcoin Cash',
    socialHandles: ['twitter.com/bitgreecom','t.me/bitgreecom','instagram.com/bitgree/','reddit.com/r/Bitgree/'],
    videos: ["https://www.youtube.com/embed/xpvru3kDo1s"],
    longerDescription: 'At Bitgree you can buy products with BCH in online stores that don\'t (yet) accept it.',
    tags:[],
  },
  { 
    name: 'Tw Bitcoin',
    website: 'twbitcoin.cash',
    logo: 'taiwanbitcoin.png',
    description: 'Taiwan E-Commerce shop accepting Bitcoin Cash.',
    socialHandles: ['t.me/twbitcoincash','twitter.com/twbitcoincash'],
    videos: [],
    longerDescription: '',
    tags:[],
  },
  { 
    name: 'Keys 4 Coins',
    website: 'keys4coins.com',
    logo: 'keys4coins.png',
    description: 'Keys4Coins is one of the first pc games store to accept cryptocurrency as payment.',
    socialHandles: ['facebook.com/profile.php?id=100063665073431','twitter.com/keys4coins','youtube.com/channel/UC6zeA21wVB1wcMhfZPbsV0Q'],
    videos: [],
    longerDescription: 'Accepting BCH! Our store is simple to use and you can shop quickly. We do not require any more customer details than required by law. Checkout is quick and simple. ',
  }, 
  { 
    name: 'Alfa',
    website: 'alfa.top',
    logo: 'alfatop.png',
    description: 'Top-up your prepaid mobile phone with Crypto!',
    socialHandles: ['twitter.com/alfat0p'],
    videos: [],
    longerDescription: 'Available in 188 countries - over 1736 of the worlds largest mobile operators. Use BCH to top-up your prepaid mobile phone!',
  },
 
  { 
    name: 'Travala',
    website: 'travala.com',
    logo: 'travala.png',
    description: 'Book your next travel plans with Travala using Cryptocurrency. Including BCH!',
    socialHandles: ['twitter.com/travalacom','facebook.com/travala/','t.me/travala','discord.com/invite/CkayVgx6nY','instagram.com/travalacom/','.reddit.com/r/Travala/','linkedin.com/company/travalacom/'],
    videos: [],
    longerDescription: 'Founded in 2017, Travala.com is the leading crypto-native travel booking service with 2,200,000+ properties in 230 countries, 400,000+ activities, and 600+ airlines globally.',
  },
  { 
    name: 'Newegg',
    website: 'newegg.com',
    logo: 'newegg.png',
    description: 'Use Bitpay on Newegg to spend your Crypto for all your electronic needs!',
    socialHandles: ['discord.com/invite/newegg','twitch.tv/newegg','youtube.com/user/newegg','instagram.com/newegg/','twitter.com/Newegg'],
    videos: [],
    longerDescription: ` In fact, we’ve been accepting digital currencies since 2014, when we were the first major retailer to accept Bitcoin as a payment option. \n
    checking out with BitPay on Newegg is easy and secure. Refunds for purchases made via BitPay can be processed either directly to BitPay or as a Newegg Gift Card. All returns follow our return policy.
    `,
  },
  { 
    name: 'Bitpays Directory',
    website: 'bitpay.com/directory/',
    logo: 'bitpay.png',
    description: '250+ companies & stores accept cryptocurrecy using Bitpay! Check the list out here.',
    socialHandles: ['twitter.com/BitPay','linkedin.com/company/bitpay-inc-'],
    videos: [],
    longerDescription: 'Shop directly with thousands of merchants that accept Bitcoin and other cryptocurrencies. Pay with Bitcoin Cash and other crypto straight from all of the most popular wallets.',
  }, 
  { 
    name: 'AriaVPN',
    website: 'ariavpn.net/ref/ltvNk ',
    logo: 'ariavpn.png',
    description: 'AriaVPN is a VPN service that accepts payments with BCH.  ',
    socialHandles: ['twitter.com/ariavpn_net','facebook.com/ariavpn.net','instagram.com/ariavpn_net/'],
    videos: ["https://www.youtube.com/embed/k7nYwwhVeVY"],
    longerDescription: `A VPN, or Virtual Private Network, is a technology that creates a secure and encrypted connection for your device, such as a computer, tablet, smartphone or network router.`,
  },
]

export default storesAds