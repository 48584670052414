import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import donateqr from '../../assets/logos/donateqr.png'
import donateqrlarge from '../../assets/logos/donateqrlarge.png'
function Footer() {
  
  // Use state to track the current page if needed
    const [currentPage, setCurrentPage] = useState('home');
  
    // Function to toggle the current page if needed
    const togglePage = (page: string) => {
      setCurrentPage(page);
    };
    const [showQRModal, setShowQRModal] = useState(false);

    const toggleQRModal = () => setShowQRModal(!showQRModal);

    return (
      <StyledFooter>
        <ContactContainer>
          <StyledSocialIcons> 
            <a href="https://t.me/helpmedotcash"><i className="fab fa-telegram"></i></a>
          </StyledSocialIcons>

            Thank you to all the donators!
            <StyledSocialUpdated> 
           Last updated: Sept 2024
          </StyledSocialUpdated>
        </ContactContainer>
  

        <AddressBox>
          bitcoincash:qqapztt9l3f7r2htuc4006m28a5kqwfxfgck8r027a
       
          <StyledAddressImage onClick={toggleQRModal}>
                    <img src={donateqr} alt="Donate QR Code" />
          </StyledAddressImage>
        </AddressBox>
                {/* Add a Link to your Submissions Page */}
                <StyledLink to="/submissions">Submit a new project or update an active one!</StyledLink>

                {showQRModal && (
                <QRModal>
                    <QRModalContent>
                        <CloseButton onClick={toggleQRModal}>&times;</CloseButton>
                        <img src={donateqrlarge} alt="Donate QR Code" style={{ maxWidth: '100%', height: 'auto' }} />
                    </QRModalContent>
                </QRModal>
            )}
      </StyledFooter>

    );
  }

  const StyledFooter = styled.div`
  height: 100px;
  width: 100%;
  background-color: black;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 1px solid #0AC18E;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: 900px) {
    flex-direction: column;
    height: auto;
    text-align: center;
  }
`;

const ContactContainer = styled.div`
  color: #F8F8F8;
  font-size: 18px;
  text-align: center;
  height: 100%;
  max-width: 100%; /* Add max-width to prevent overlapping */

  a {
    color: #F8F8F8;
    text-decoration: none;
  }

  @media (max-width: 900px) {
    margin-bottom: 15px;
  }
`;
const StyledSocialIcons = styled.div`
  display: flex;
  gap: 10px;
  color: #F8F8F8;
  font-size: 20px;
  text-decoration: none;
  overflow-x: hidden;
  
`;
const StyledSocialUpdated= styled.div`
  display: flex;
  gap: 10px;
  color: #F8F8F8;
  font-size: 15px;
  text-decoration: none;
  overflow-x: hidden;
`;
const AddressBox = styled.div`
  position: absolute;
  margin-top: 20px;
  height: 100%;
  left: 20px;
  color: #F8F8F8;
  font-size: 14px;
  text-align: center;
  max-width: 100%; /* Add max-width to prevent overlapping */

  p {
    margin: 0;
  }

  a {
    color: #F8F8F8;
    text-decoration: none;
    margin-left: 10px;
  }

  @media (max-width: 900px) {
    position: static;
    margin-top: 0;
  }
`;
const StyledAddressImage = styled.div`
  
display: fixed;
  gap: 10px;
  img {
    max-width: 60px;
    height: auto;
  }

`;
const StyledLink = styled(Link)`
  position: absolute;
  top: 10px; /* Adjust top positioning as needed */
  right: 10px; /* Adjust right positioning as needed */
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #0AC18E; /* Green border color */
  color: #F8F8F8; /* White text color */
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s, box-shadow 0.3s; /* Transition for glow effect */
  @media (max-width: 900px) {
    position: static;
    margin-top: 0;
  }
  &:hover {
    background-color: #0AC18E; /* Green background color on hover */
    border: 2px solid #fff;
    box-shadow: 0 0 10px rgba(0, 128, 0, 0.5); /* Glow effect on hover */
  }


`;

const QRModal = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
`;

const QRModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 1px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px; // Adjust the max-width as needed
`;

const CloseButton = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  &:hover, &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default Footer;