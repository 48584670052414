/* { 
  name: '',
  website: '',
  logo: '',
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
}, */

const contentCreatorsAds =[
      { 
        name: 'Bitcoin Cash Podcast',
        website: 'bitcoincashpodcast.com',
        logo: 'bitcoincashpodcast.png',
        description: 'The Bitcoin Cash Podcast combines the latest cryptocurrency and Bitcoin Cash news with deep dives on BCH topics, featuring a variety of guests.',
        socialHandles: ['www.youtube.com/@BitcoinCashPodcast','t.me/thebitcoincashpodcast_discussion','t.me/thebitcoincashpodcast','twitter.com/TheBCHPodcast','www.reddit.com/r/BitcoinCashPodcast/','www.instagram.com/thebitcoincashpodcast/','www.twitch.tv/thebitcoincashpodcast','www.youtube.com/channel/UC22kOwMuIzJCOtAPjoIjkFA','odysee.com/@BitcoinCashPodcast:2','noise.app/TheBitcoinCashPodcast','cashrain.com/bitcoincashpodcast','cointr.ee/bitcoincashpodcast','www.patreon.com/bitcoincashpodcast'],
        videos: ['https://www.youtube.com/embed/ixpuLmvNel4'],
        longerDescription: `The Bitcoin Cash Podcast started in January 2021.  
        The podcast has 4 aims:  To accelerate adoption by explaining how and why Bitcoin Cash will become the worlds money \n
        To make news updates on the project accessible to all adopters (not everyone has time to follow it religiously) \n
        To serve as a historical record of a once-in-a-millenia event (global adoption of a new money) \n
        To make this historic social revolution an interesting and entertaining one to partake in.   `,
        tags:[],
      },
      { 
        name: 'Casual BCH',
        website: 'open.spotify.com/show/4QuyQ5LHBb410sFXWINjd9',
        logo: 'casualbch.png',
        description: 'A laid back podcast mainly about BCH with Sayoshi Nakamario, VinRadiant, and Cheaplightning',
        socialHandles: ['www.youtube.com/playlist?list=PL0cthp4XeZ1OF0R332tnzjSul5ii-2uZH','open.spotify.com/show/4QuyQ5LHBb410sFXWINjd9'],
        videos: ['https://www.youtube.com/embed/nTPpc1wP1Lk'],
        longerDescription: `CasualBCH attempts to maintain weekly episodes available on Spotify and other podcasting apps.  
        Hosted by Sayoshi Nakamario, VinRadiant, and Cheaplightning who have all been into crypto since ~2011. `,
        tags:[],
      },
      { 
        name: 'General Protocol Spaces',
        website: 'youtube.com/@generalprotocols',
        logo: 'generalprotocols.png',
        description: 'General Protocols Twitter Spaces!',
        socialHandles: ['t.me/GeneralProtocols','twitter.com/GeneralProtocol','www.reddit.com/user/GeneralProtocols'],
        videos: ['https://www.youtube.com/embed/707-DPzhdA8'],
        longerDescription: `General Protocol hosts  LIVE Twitter Spaces to discuss the Bitcoin Cash Ecosystem! Miss it live? No worries! You can watch later on Twitter or Youtube.`,
        tags:[],
      },
      { 
        name: 'Paytaca Talks',
        website: 'youtube.com/watch?v=jj167-51kfI&list=PL2DaalNSEP8K06J0aRBrjdi3GFNXpSuj1',
        logo: 'paytaca.png',
        description: 'Paytaca Talks is a series of episodes focusing about Paytaca app, BitcoinCash, cryptocurrency and other interrelated topics',
        socialHandles: ['open.spotify.com/show/3ntwfrqtUG8IRJdmteKD22','instagram.com/paytaca/','facebook.com/paytaca/','tiktok.com/@paytaca?lang=en','twitter.com/_paytaca_','t.me/PaytacaWalletApp'],
        videos: ['https://open.spotify.com/embed/episode/3DBRzAHjMhhhckpThPn2Yl'],
        longerDescription: `Paytaca Talks is a series of episodes with Joemar Taganna, President and CEO, and Aaron JP Almadro, the marketing director of Paytaca, focusing about Paytaca app, BitcoinCash, cryptocurrency and other interrelated topics with the goal of disseminating reliable information to cryptocurrency users as well as to the general public. Paytaca aims to be one of the prime movers in making this revolution transition from centralised to decentralized cash to happen. We would be at the forefront in this effort to introduce decentralized peer-to-peer electronic cash to the world by making it even more accessible to everyone, everywhere.In this Paytaca Talks, you can expect more in-depth information and tutorials about cryptocurrency and other related tech news. `,
        tags:[],
      },
      { 
        name: 'La Economia P2P Podcast',
        website: 'youtube.com/@LaEconomiaP2P',
        logo: 'laeconomia.png',
        description: 'A podcast with Ian Blas from BCH Argentina in Spanish',
        socialHandles: ['t.me/LaEconomiaP2P','/twitter.com/laeconomiap2p','instagram.com/laeconomiap2p/'],
        videos: ['https://www.youtube.com/embed/6JrVWA67wWw'],
        longerDescription: 'Podcast de economía, libertad, privacidad y #BitcoinCash • P2P Electronic Cash, by Satoshi Nakamoto • Construyendo la economía paralela',
        tags:['Spanish'],
      },
      { 
        name: 'BitcoinCash Foundation',
        website: 'youtube.com/@BitcoinCashFoundation',
        logo: 'bchf.png',
        description: 'The BitcoinCashFoundation post Weekly news on the BCH Ecosystem!',
        socialHandles: ['twitter.com/BCHF_ORG','t.me/BitcoinCashFoundation','t.me/BCHFNews','publish0x.com/bitcoin-cash2','medium.com/@BCHF_ORG,','read.cash/@BCHF','tiktok.com/@bchf_org'],
        videos: ["https://www.youtube.com/embed/JNI-ZrbCYAY" ],
        longerDescription: 'Someone needs to do something! The BCHF brings to you weekly news on the BCH ecosystem, keeps Coinmarketcap news and info upto date,  and more!',
        tags:[],
      },
      { 
        name: 'Ray Uses Bitcoin Cash',
        website: 'youtube.com/@rayusesbitcoincash',
        logo: 'ray.png',
        description: 'Join us for electrifying adventures, showcasing Bitcoin Cash`s boundless potential in everyday life',
        socialHandles: ['twitter.com/whiterabbitcc2','cashrain.com/es/rayusesbitcoincash'],
        videos: ["https://www.youtube.com/embed/iUDsC6kc_zg"],
        longerDescription: '',
        tags:[],
      },
      { 
        name: 'Bitcoin Jason',
        website: 'youtube.com/@BitcoinJason',
        logo: 'jason.jpg',
        description: 'Bitcoin Cash Life Style, Merchant adoption services and consumer guide.',
        socialHandles: ['noise.app/u/BitcoinJason','cashrain.com/BitcoinJason'],
        videos: [],
        longerDescription: '',
        tags:[],
      },
      { 
        name: 'Nemecis1000 ',
        website: 'youtube.com/@Nemecis1000/',
        logo: 'Nemecis1000.png',
        description: 'A youtube channel in spanish that makes videos about BCH and software',
        socialHandles: ['twitter.com/nemecis1000'],
        videos: ["https://www.youtube.com/embed/8SjPLt4aWGI"],
        longerDescription: 'Hola tengo Videos en español sobre algunas cosas de BitcoinCash y sofwrelibre',
        tags:['Spanish'],
      },
      { 
        name: 'Paul',
        website: 'youtube.com/@rspl2024',
        logo: 'paul.png',
        description: 'A youtube channel on the BCH Ecosystem',
        socialHandles: ['twitter.com/Rspl382'],
        videos: ["https://www.youtube.com/embed/0h_9EIG4RLA"],
        longerDescription: '',
        tags:[], 
      },
    ]

export default contentCreatorsAds