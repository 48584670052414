


/* { 
  name: '',
  website: '',
  logo: '',
  score: ,
  columns: ['', 'X', '',  '', '',''],
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
}, */

 const developmentAds  = [
      { 
        name: 'Cash Improvement Proposals',
        website: 'gitlab.com/im_uname/cash-improvement-proposals/-/blob/master/CHIPs.md?ref_type=heads',
        logo: 'nologo.png',
        description: 'The CHIPs is the public documentation of a shared ecosystem process to achieve one positive change on the BCH network . ',
        socialHandles: ['t.me/GeneralProtocols','read.cash/@GeneralProtocols/a-focus-on-the-bitcoin-cash-ecosystem-08-chips-f020b9bc','bitcoincashresearch.org/t/chips-a-more-detailed-process-recommendation/309/25'],
        videos: ['https://www.youtube.com/embed/9rB83MocRrQ'],
        longerDescription: 'For a completely decentralized network, such as Bitcoin Cash, having a robust process for upgrading the network is of huge benefit to all stakeholders.',
        tags:[],
      },
      { 
        name: 'Bitcoincash Research',
        website: 'bitcoincashresearch.org/',
        logo: 'bchresearch.png',
        description: 'Vision The Bitcoin Cash ecosystem requires a common space where pioneers, innovators and developers can collaborate productively.',
        socialHandles: [],
        videos: [],
        longerDescription: '',
        tags:[],
      },  
      { 
        name: 'Simple Ledger Protocol',
        website: 'slp.dev/',
        logo: 'slp.png',
        description: 'Simple Ledger Protocol developed ontop of the Bitcoin Cash blockchain. ',
        socialHandles: ['t.me/simpleledger'],
        videos: [],
        longerDescription: '',
        tags:[],
      },
    ]
export default developmentAds